import { Box, Container, Flex, Text, useBoolean } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { MdUpdate } from 'react-icons/md';

import Button from '~sf/components/ui/Button';

import checkVersionIsLatest from './checkVersionIsLatest';
import { VERSION_CHECK_INTERVAL, VERSION_RELOAD_FLAG } from './constants';

/**
 * Show a modal dialog whenever the current version is out of date so the user can reload the page
 * if something goes wrong.
 *
 * This uses the file in `public/version.json` to check the latest version, as that one is the same file
 * for all builds.
 *
 * Once called this method sets up an interval to check every minute.
 */
const CheckVersion = () => {
  const [isOutdated, setIsOutdated] = useBoolean(false);
  const hasAcknowledged = localStorage.getItem(VERSION_RELOAD_FLAG) === 'true';

  const checkVersion = useCallback(async () => {
    const isLatestVersion = await checkVersionIsLatest();

    if (isLatestVersion) {
      localStorage.removeItem(VERSION_RELOAD_FLAG);
      setIsOutdated.off();
    } else {
      setIsOutdated.on();
    }
  }, [setIsOutdated]);

  useEffect(() => {
    void checkVersion();
    const intervalId = setInterval(async () => checkVersion(), VERSION_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [checkVersion]);

  const onReload = () => {
    localStorage.setItem(VERSION_RELOAD_FLAG, 'true');

    window.location.reload();
  };

  if (!isOutdated || hasAcknowledged) {
    return null;
  }

  return (
    <Box bg="primary.500" color="white" py={2}>
      <Container maxW="container.xl">
        <Flex direction="row" gap={2} wrap="wrap" alignItems="center">
          <Text fontWeight="bold">Update available!</Text>
          <Text>
            There is a new version of Safebeat available, please reload the page as soon as possible
            to avoid any issues.
          </Text>

          <Button
            eventName="check-version--update"
            size="sm"
            leftIcon={<MdUpdate />}
            onClick={onReload}
          >
            Update Now
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default CheckVersion;
