import { Entitlements } from "~sf/models";

/**
 * Backend to Frontend entitlement mapping
 *
 * All entitlements from the backend must be defined (TypeScript will ensure that is the case)
 * Whenever you want to check an entitlement throughout the codebase add it here if it's not available yet
 * following the examples here (i.e. "canDeleteSomeResource"), and then check it as `const {canDeleteSomeResource} = useEntitlements();`
 * And "canDeleteSomeResource" will either be `true` or `false` depending on whether the mapped backend entitlement is returned.
 */
export const entitlementsMapping = {
  "account_auth.admin_group_deprecated_entitlement":                    null,
  "account_auth.clinic_admin_group_deprecated_entitlement":             null,
  "account_auth.clinic_staff_group_deprecated_entitlement":             null,
  "account_auth.doctor_group_deprecated_entitlement":                   null,
  "account_auth.patient_group_deprecated_entitlement":                  null,
  "account_auth.upload_profile_image_userprofileresource_entitlement":  null,
  //
  "action_log.admin_visibility_actionlogresource_entitlement":          "canSeeActionLog",
  "action_log.clinic_visibility_actionlogresource_entitlement":         "canSeeActionLog",
  //
  "annotation_portal.list_annotationjobpageresource_entitlement":       "canListAnnotationJobs",
  "annotation_portal.statistics_annotationjobpageresource_entitlement": "canViewAnnotationJobStats",

  "clinic.delete_for_any_clinic_clinicstaffpageresource_entitlement":     "canDeleteStaff",
  "clinic.delete_for_any_clinic_patientpageresource_entitlement":         "canDeletePatients",
  "clinic.delete_for_own_clinic_clinicstaffpageresource_entitlement":     "canDeleteStaff",
  "clinic.delete_for_own_clinic_patientpageresource_entitlement":         "canDeletePatients",
  "clinic.list_clinicpageresource_entitlement":                           "canListClinics",
  "clinic.list_clinicstaffpageresource_entitlement":                      "canListStaff",
  "clinic.list_patientpageresource_entitlement":                          "canListPatients",
  "clinic.set_admin_staff_clinicstaffpageresource_entitlement":           null,
  "clinic.submit_delete_clinicpageresource_entitlement":                  "canDeleteClinic",
  "clinic.submit_new_clinicpageresource_entitlement":                     "canCreateClinics",
  "clinic.submit_new_for_any_clinic_clinicstaffpageresource_entitlement": "canCreateStaff",
  "clinic.submit_new_for_any_clinic_patientpageresource_entitlement":     "canCreatePatients",
  "clinic.submit_new_for_own_clinic_clinicstaffpageresource_entitlement": "canCreateStaff",
  "clinic.submit_new_for_own_clinic_patientpageresource_entitlement":     "canCreatePatients",
  "clinic.submit_update_clinicpageresource_entitlement":                  "canEditClinic",
  "clinic.update_for_any_clinic_clinicstaffpageresource_entitlement":     "canUpdateStaff",
  "clinic.update_for_any_clinic_patientpageresource_entitlement":         "canUpdatePatients",
  "clinic.update_for_own_clinic_clinicstaffpageresource_entitlement":     "canUpdateStaff",
  "clinic.update_for_own_clinic_patientpageresource_entitlement":         "canUpdatePatients",
  "clinic.view_any_clinic_patientpageresource_entitlement":               null,
  "clinic.view_any_clinicpageresource_entitlement":                       null,
  "clinic.view_any_clinicstaffpageresource_entitlement":                  "canViewStaffForClinic",
  "clinic.view_configuration_clinicpageresource_entitlement":             "canViewClinicConfiguration",
  "clinic.view_own_clinic_patientpageresource_entitlement":               "canViewPatientClient",
  "clinic.view_own_clinicpageresource_entitlement":                       "canViewOwnClinic",
  "clinic.view_own_clinicstaffpageresource_entitlement":                  "canViewStaffForClinic",
  "clinic.view_patient_client_patientpageresource_entitlement":           "canViewPatientClient",

  "device.inject_hosted_ecg_signal_file_any_clinic_devicepageresource_entitlement": null,
  "device.list_devicepageresource_entitlement":                                     "canListDevices",
  "device.submit_delete_for_any_clinic_devicepageresource_entitlement":             "canDeleteDevices",
  "device.submit_new_for_any_clinic_devicepageresource_entitlement":                "canCreateDevices",
  "device.submit_update_for_any_clinic_devicepageresource_entitlement":             "canUpdateDevices",
  "device.submit_update_own_clinic_devices_devicepageresource_entitlement":         "canUpdateDevices",
  "device.view_any_clinic_devices_devicepageresource_entitlement":                  null,
  "device.view_own_clinic_devices_devicepageresource_entitlement":                  null,

  "drug_loading_protocol.approve_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement":                             "canApproveBundle",
  "drug_loading_protocol.approve_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement":                             "canApproveBundle",
  "drug_loading_protocol.approve_medication_dosage_for_any_clinic_medicationdosagepageresource_entitlement":                    "canApproveDose",
  "drug_loading_protocol.approve_medication_dosage_for_own_clinic_medicationdosagepageresource_entitlement":                    "canApproveDose",
  "drug_loading_protocol.change_medication_expected_taken_at_for_any_clinic_medicationdosagepageresource_entitlement":          "canChangeMedicationDosageExpectedAtTime",
  "drug_loading_protocol.change_medication_expected_taken_at_for_own_clinic_medicationdosagepageresource_entitlement":          "canChangeMedicationDosageExpectedAtTime",
  "drug_loading_protocol.change_protocol_configuration_for_any_clinic_drugloadingprotocolpageresource_entitlement":             "canChangeProtocolConfiguration",
  "drug_loading_protocol.change_protocol_configuration_for_own_clinic_drugloadingprotocolpageresource_entitlement":             "canChangeProtocolConfiguration",
  "drug_loading_protocol.change_selected_bundle_recording_for_any_clinic_ecgrecordingbundlepageresource_entitlement":           null,
  "drug_loading_protocol.change_selected_bundle_recording_for_own_clinic_ecgrecordingbundlepageresource_entitlement":           null,
  "drug_loading_protocol.download_protocol_report_for_any_clinic_drugloadingprotocolpageresource_entitlement":                  "canDownloadReport",
  "drug_loading_protocol.download_protocol_report_for_own_clinic_drugloadingprotocolpageresource_entitlement":                  "canDownloadReport",
  "drug_loading_protocol.empty_ecg_bundle_only_after_dosage_approval_drugloadingprotocoltimelineentrypageresource_entitlement": null,
  "drug_loading_protocol.end_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement":                              "canEndProtocol",
  "drug_loading_protocol.end_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement":                              "canEndProtocol",
  "drug_loading_protocol.extend_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement":                           "canExtendProtocol",
  "drug_loading_protocol.extend_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement":                           "canExtendProtocol",
  "drug_loading_protocol.initiate_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement":                         "canInitiateProtocol",
  "drug_loading_protocol.initiate_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement":                         "canInitiateProtocol",
  "drug_loading_protocol.move_bundle_recording_for_any_clinic_ecgrecordingbundlepageresource_entitlement":                      null,
  "drug_loading_protocol.move_bundle_recording_for_own_clinic_ecgrecordingbundlepageresource_entitlement":                      null,
  "drug_loading_protocol.reject_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement":                              "canRejectEcg",
  "drug_loading_protocol.reject_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement":                              "canRejectEcg",
  "drug_loading_protocol.release_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement":                             "canReleaseEcgs",
  "drug_loading_protocol.release_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement":                             "canReleaseEcgs",
  "drug_loading_protocol.release_recording_for_any_clinic_ecgrecordingtoecgbundlepageresource_entitlement":                     null,
  "drug_loading_protocol.release_recording_for_own_clinic_ecgrecordingtoecgbundlepageresource_entitlement":                     null,
  "drug_loading_protocol.reopen_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement":                           "canReopenProtocol",
  "drug_loading_protocol.reopen_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement":                           "canReopenProtocol",
  "drug_loading_protocol.report_medication_taken_for_any_clinic_medicationdosagepageresource_entitlement":                      "canReportMedicationTaken",
  "drug_loading_protocol.report_medication_taken_for_own_clinic_medicationdosagepageresource_entitlement":                      "canReportMedicationTaken",
  "drug_loading_protocol.unapprove_medication_dosage_for_any_clinic_medicationdosagepageresource_entitlement":                  "canUnapproveMedicationDosage",
  "drug_loading_protocol.unapprove_medication_dosage_for_own_clinic_medicationdosagepageresource_entitlement":                  "canUnapproveMedicationDosage",
  "drug_loading_protocol.view_all_recordings_ecgrecordingtoecgbundlepageresource_entitlement":                                  null,
  "drug_loading_protocol.view_bundle_for_any_clinic_ecgrecordingbundlepageresource_entitlement":                                null,
  "drug_loading_protocol.view_bundle_for_own_clinic_ecgrecordingbundlepageresource_entitlement":                                null,
  "drug_loading_protocol.view_ecg_review_page_drugloadingprotocolpageresource_entitlement":                                     null,
  "drug_loading_protocol.view_only_selected_recording_ecgrecordingtoecgbundlepageresource_entitlement":                         null,
  "drug_loading_protocol.view_protocol_for_any_clinic_drugloadingprotocolpageresource_entitlement":                             null,
  "drug_loading_protocol.view_protocol_for_own_clinic_drugloadingprotocolpageresource_entitlement":                             null,
  "drug_loading_protocol.view_protocol_timeline_entry_for_any_clinic_drugloadingprotocoltimelineentrypageresource_entitlement": null,
  "drug_loading_protocol.view_protocol_timeline_entry_for_own_clinic_drugloadingprotocoltimelineentrypageresource_entitlement": null,
  "drug_loading_protocol.view_unreleased_ecg_bundle_ecgrecordingbundlepageresource_entitlement":                                null,

  "ecg_analysis.submit_ecg_analysis_for_any_clinic_ecganalysispageresource_entitlement":          "canSubmitEcgAnalysis",
  "ecg_analysis.submit_ecg_analysis_for_own_clinic_ecganalysispageresource_entitlement":          "canSubmitEcgAnalysis",
  "ecg_analysis.submit_ecg_analysis_for_released_ecg_bundle_ecganalysispageresource_entitlement": null,
  //
  "ecg_recording.download_ecg_file_for_any_clinic_ecgrecordingpageresource_entitlement":          null,
  "ecg_recording.download_ecg_file_for_own_clinic_ecgrecordingpageresource_entitlement":          null,
  "ecg_recording.submit_ecg_file_for_any_clinic_ecgrecordingpageresource_entitlement":            "canUploadEcgFile",
  "ecg_recording.submit_ecg_file_for_own_clinic_ecgrecordingpageresource_entitlement":            "canUploadEcgFile",
  "ecg_recording.update_ecg_notes_ecgrecordingpageresource_entitlement":                          "canUpdateNotes",

  "manual_patient_measurement.submit_manual_patient_measurement_for_any_clinic_manualpatientmeasurementpageresource_entitlement": null,
  "manual_patient_measurement.submit_manual_patient_measurement_for_own_clinic_manualpatientmeasurementpageresource_entitlement": null,
  "manual_patient_measurement.view_manual_patient_measurement_for_any_clinic_manualpatientmeasurementpageresource_entitlement":   null,
  "manual_patient_measurement.view_manual_patient_measurement_for_own_clinic_manualpatientmeasurementpageresource_entitlement":   null,

  "notification.list_any_clinic_notificationsendrequestlogresource_entitlement":                 "canListNotifications",
  "notification.list_own_clinic_notificationsendrequestlogresource_entitlement":                 "canListNotifications",
  "notification.send_patient_message_any_clinic_notificationsendrequestlogresource_entitlement": null,
  "notification.send_patient_message_own_clinic_notificationsendrequestlogresource_entitlement": null,

  "patient_response.list_any_clinic_patientconversationresource_entitlement": "canListPatientConversations",
  "patient_response.list_own_clinic_patientconversationresource_entitlement": "canListPatientConversations",
} as const satisfies Record<Entitlements, string | null>;

export type EntitlementOptions = Exclude<(typeof entitlementsMapping)[keyof typeof entitlementsMapping], null>;
