import { EntitlementActionMap } from './entitlements/generateEntitlementMap';
import useStore from './useStore';

function useEntitlements(): EntitlementActionMap {
  const { entitlementMap } = useStore((state) => ({
    entitlementMap: state.entitlementMap,
  }));

  return entitlementMap;
}

export default useEntitlements;
