import { fetcher } from '~sf/api/fetch';

// ---------------------------------------------------
//              Export Custom Clients
// ---------------------------------------------------
export * from './custom';
// ---------------------------------------------------

// API Info
export const apiInfoClient = fetcher.path('/').method('get').create();

// Auth
export const loginClient = fetcher.path('/dashboard/auth/login/password').method('post').create();
export const loginLinkClient = fetcher
  .path('/dashboard/auth/login/one-time-password/initiate')
  .method('post')
  .create();
export const loginLinkValidateClient = fetcher
  .path('/dashboard/auth/login/one-time-password')
  .method('post')
  .create();
export const logoutClient = fetcher.path('/dashboard/auth/logout').method('post').create();
export const profileClient = fetcher.path('/dashboard/auth/profile/me').method('get').create();
export const entitlementsClient = fetcher
  .path('/dashboard/auth/entitlement/me')
  .method('get')
  .create();

// Clinic
export const clinicClient = fetcher.path('/dashboard/clinic/v2/').method('get').create();
export const createClinicClient = fetcher.path('/dashboard/clinic/v2/').method('post').create();
export const editClinicClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/')
  .method('put')
  .create();
export const getClinicDataClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/')
  .method('get')
  .create();
export const deleteClinicDataClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/')
  .method('delete')
  .create();

export const createStaffClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/staff/')
  .method('post')
  .create();
export const getStaffListClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/staff/')
  .method('get')
  .create();
export const getAllStaffClient = fetcher
  .path('/dashboard/clinic/v2/staff/all/')
  .method('get')
  .create();
export const getStaffClient = fetcher
  .path('/dashboard/clinic/v2/staff/{user_id}/')
  .method('get')
  .create();
export const updateStaffClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('put')
  .create();
export const deleteStaffClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('delete')
  .create();

// Patients
export const getAllPatientsClient = fetcher
  .path('/dashboard/clinic/v2/patient/all/')
  .method('get')
  .create();
export const getPatientsListClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/patient/')
  .method('get')
  .create();
export const createPatientClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/patient/')
  .method('post')
  .create();
export const createAdHocEcgAnalysisPatientClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/ad-hoc-ecg-analysis-patient/')
  .method('post')
  .create();
export const createAnonymousPatientClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/anonymous-patient/')
  .method('post')
  .create();
export const updatePatientClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/patient/{patient_id}/')
  .method('put')
  .create();
export const updateAdHocEcgAnalysisPatientClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/ad-hoc-ecg-analysis-patient/{patient_id}/')
  .method('put')
  .create();
export const updateAnonymousPatientClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/anonymous-patient/{patient_id}/')
  .method('put')
  .create();

export const getPatientClient = fetcher
  .path('/dashboard/clinic/v2/patient/{patient_id}/')
  .method('get')
  .create();
export const deletePatientClient = fetcher
  .path('/dashboard/clinic/v2/patient/{patient_id}/delete/')
  .method('delete')
  .create();

export const getPatientActionLog = fetcher
  .path('/dashboard/action-log/patient/{patient_id}/actions/')
  .method('get')
  .create();

// Providers
export const editProviderClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('put')
  .create();
export const getProviderClient = fetcher
  .path('/dashboard/clinic/v2/staff/{user_id}/')
  .method('get')
  .create();
export const deleteProviderClient = fetcher
  .path('/dashboard/clinic/v2/{clinic_id}/staff/{user_id}/')
  .method('delete')
  .create();

// forgotpassword
export const forgotPasswordClient = fetcher
  .path('/dashboard/auth/change-password/one-time-password/initiate')
  .method('post')
  .create();
export const resetPasswordClient = fetcher
  .path('/dashboard/auth/change-password/one-time-password')
  .method('post')
  .create();

// contact clinic
export const contactClient = fetcher.path('/dashboard/contact/').method('post').create();

// Annotation Portal
export const getAnnotationJobsClient = fetcher
  .path('/dashboard/annotation-portal/annotation-job/')
  .method('get')
  .create();
export const getAnnotationJobsAssignedClient = fetcher
  .path('/dashboard/annotation-portal/user-assigned-annotation-job/me')
  .method('get')
  .create();
export const getAnnotationTasksClient = fetcher
  .path('/dashboard/annotation-portal/annotation-job/{annotation_job_id}/tasks/receive')
  .method('patch')
  .create();
export const getAnnotationTaskByFilenameClient = fetcher
  .path('/dashboard/annotation-portal/annotation-job/{annotation_job_id}/tasks/receive-single')
  .method('patch')
  .create();
export const markAnnotationTaskUnreadableClient = fetcher
  .path('/dashboard/annotation-portal/annotation-task/{annotation_task_id}/mark-unreadable')
  .method('post')
  .create();
export const saveAnnotationTaskResultClient = fetcher
  .path('/dashboard/annotation-portal/annotation-task/{annotation_task_id}/result')
  .method('post')
  .create();

export const needsReviewAnnotationTaskResultClient = fetcher
  .path('/dashboard/annotation-portal/annotation-task/{annotation_task_id}/needs-review')
  .method('post')
  .create();

export const getAnnotationTaskHistoryClient = fetcher
  .path('/dashboard/annotation-portal/annotation-task/{annotation_task_id}/history')
  .method('get')
  .create();

export const getJobAnnotatorsClient = fetcher
  .path(
    '/dashboard/annotation-portal/annotation-job/{annotation_job_id}/originating-result-annotators',
  )
  .method('get')
  .create();

// SCAN Drug loading protocol
export const getDrugLoadingProtocolClient = fetcher
  .path('/dashboard/drug-loading-protocol/patient/{patient_id}/latest/')
  .method('get')
  .create();

export const initiateDrugLoadingProtocolClient = fetcher
  .path('/dashboard/drug-loading-protocol/patient/{patient_id}/initiate/')
  .method('post')
  .create();

export const endDrugLoadingProtocolClient = fetcher
  .path('/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/end/')
  .method('patch')
  .create();

export const extendDrugLoadingProtocolClient = fetcher
  .path('/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/extend/')
  .method('patch')
  .create();

export const reopenDrugLoadingProtocolClient = fetcher
  .path('/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/reopen/')
  .method('patch')
  .create();

export const updateDrugLoadingProtocolConfigurationClient = fetcher
  .path('/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/configuration/')
  .method('patch')
  .create();

export const getProtocolTimelineClient = fetcher
  .path('/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/timeline/')
  .method('get')
  .create();

export const getEcgBundleSelectedRecordingClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/selected-recording-w-analysis/',
  )
  .method('get')
  .create();

export const getAllEcgsInBundle = fetcher
  .path(
    '/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/all-recordings-w-analysis/',
  )
  .method('get')
  .create();

export const releaseEcgBundleClient = fetcher
  .path('/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/release/')
  .method('patch')
  .create();

export const setSelectedEcgRecordingClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/ecg-recording/{ecg_recording_id}/selected/',
  )
  .method('patch')
  .create();

export const unReleaseEcgBundleClient = fetcher
  .path('/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/hold/')
  .method('patch')
  .create();

export const approveEcgBundleClient = fetcher
  .path('/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/approve/')
  .method('post')
  .create();

export const unApproveEcgBundleClient = fetcher
  .path('/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/approval/')
  .method('delete')
  .create();

export const rejectEcgRecordingClient = fetcher
  .path('/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/reject/')
  .method('post')
  .create();

export const saveAnalysisAndDecisionClient = fetcher
  .path('/dashboard/ecg-analysis/ecg-measurement-recording/{ecg_recording_id}/analysis/')
  .method('post')
  .create();

export const approveMedicationDosageClient = fetcher
  .path('/dashboard/drug-loading-protocol/medication-dosage/{medication_dosage_id}/approval/')
  .method('post')
  .create();

export const unApproveMedicationDosageClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/medication-dosage/approval/{medication_dosage_approval_id}/',
  )
  .method('delete')
  .create();

export const getManualMeasurementsClient = fetcher
  .path('/dashboard/manual-patient-measurement/patient/{patient_id}/latest_for_kinds/')
  .method('get')
  .create();

export const saveManualMeasurementsClient = fetcher
  .path('/dashboard/manual-patient-measurement/patient/{patient_id}/')
  .method('post')
  .create();

export const reportDoseTakenClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/medication-dosage/approval/{medication_dosage_approval_id}/taken/',
  )
  .method('patch')
  .create();

export const changeExpectedTakenAtClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/medication-dosage/{medication_dosage_id}/expected-taken-at/',
  )
  .method('patch')
  .create();

export const getEcgPlacementOptionsClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/available-upload-dosage-placements/',
  )
  .method('get')
  .create();

export const fetchEcgFromApiClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/device/{device_id}/fetch-device-api/',
  )
  .method('post')
  .create();
export const fetchEcgFromApiForBundleClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/ecg-recording-bundle/{ecg_recording_bundle_id}/device/{device_id}/fetch-device-api/',
  )
  .method('post')
  .create();

export const getNotificationLog = fetcher
  .path('/dashboard/notification/log/drug_loading_protocol/{drug_loading_protocol_id}/doctor-sms/')
  .method('get')
  .create();

export const notifyAdminsPatientActivity = fetcher
  .path('/dashboard/notification/notify-admins/patient-activity/me')
  .method('post')
  .create();

export const updateEcgNotesClient = fetcher
  .path('/dashboard/ecg-recording/ecg-recording/{ecg_recording_id}/notes/')
  .method('patch')
  .create();

// Devices
export const getDevicesClient = fetcher.path('/dashboard/device/').method('get').create();
export const getDeviceClient = fetcher
  .path('/dashboard/device/{device_id}/')
  .method('get')
  .create();

export const getPatientDevicesClient = fetcher
  .path('/dashboard/device/patient/{patient_id}/')
  .method('get')
  .create();

export const createDeviceClient = fetcher.path('/dashboard/device/').method('post').create();

export const updateDeviceClient = fetcher
  .path('/dashboard/device/{device_id}/')
  .method('put')
  .create();

export const injectEcgSignalFileClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/{drug_loading_protocol_id}/device/{device_id}/inject-prerecorded-ecg-signal-file/',
  )
  .method('post')
  .create();

// Patient Client

export const getPatientViewClient = fetcher
  .path('/dashboard/patient/client/me')
  .method('get')
  .create();

export const patchPatientDoseTakenClient = fetcher
  .path(
    '/dashboard/drug-loading-protocol/medication-dosage/approval/{medication_dosage_approval_id}/taken/me/',
  )
  .method('patch')
  .create();

export const getPatientConversationClient = fetcher
  .path('/dashboard/patient-conversation/drug_loading_protocol/{drug_loading_protocol_id}/all/')
  .method('get')
  .create();

export const sendMessageToPatientClient = fetcher
  .path(
    '/dashboard/notification/custom-patient-message/drug-loading-protocol/{drug_loading_protocol_id}/to-patient/',
  )
  .method('post')
  .create();
