import { importMetaEnv } from '~sf/constants';

export const API_URL = importMetaEnv.VITE_API_URL || 'http://localhost:8000';

export const BradycardiaThreshold = 50;
export const TachycardiaThreshold = 100;

export const NAME_MAX_LENGTH = 50;
export const NAME_MIN_LENGTH = 2;
export const PASSWORD_MAX_LENGTH = 50;
export const PASSWORD_MIN_LENGTH = 2;
export const PHONE_NO_MAX_LENGTH = 20;
export const ONE_HOUR = 60 * 60 * 1000; /* ms */
export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;
export const INVALID_INDEX = -1;

export const DEFAULT_MAX_DOSE = 5;
