import { useMemo } from 'react';

import type { Entitlements } from '~sf/models';

import useStore from './useStore';

type EntitlementChecks<T extends Entitlements[]> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T]: boolean;
};

/**
 * Check whether the user has specific entitlements.
 * The function accepts a variable number of entitlements as arguments, and returns an array of booleans of the same length.
 *
 * @example const [check1] = useEntitlementsCheck('entitlement1');
 * @example const [check1, check2] = useEntitlementsCheck('entitlement1', 'entitlement2'
 *
 * @see useEntitlements.ts - for a simpler way to check entitlements
 */
function useEntitlementsCheck<T extends Entitlements[]>(
  ...entitlementCheck: T
): EntitlementChecks<T> {
  const { entitlements } = useStore((state) => ({
    entitlements: state.entitlements,
  }));

  return useMemo(
    () =>
      entitlementCheck.map((entitlement) =>
        entitlements.includes(entitlement),
      ) as EntitlementChecks<T>,
    [entitlements, entitlementCheck],
  );
}

export default useEntitlementsCheck;
